import React, { useRef, useState } from "react";
import ReactPlayer from "react-player";
import video from "./video.mp4";
import logo from './logo.svg';

const VideoPlayer = () => {
  const playerRef = useRef(null);
  const [playing, setPlaying] = useState(false);

  const handleStartButtonClick = (start, stop) => {
    setPlaying(true);
    playerRef.current.seekTo(start, "seconds");

    const internalPlayer = playerRef.current.getInternalPlayer();
    if (internalPlayer) {
      // Clear any existing playback timeout
      clearTimeout(internalPlayer.playbackTimeout);

      // Set a timeout to pause the video after the calculated duration
      internalPlayer.playbackTimeout = setTimeout(() => {
        setPlaying(false);
        internalPlayer.pause();
      }, (stop - start) * 1000);
    }
  };

  return (
    <div style={{ display: "flex", alignItems: "flex-start", height: "100vh" }}>
      <ReactPlayer
        ref={playerRef}
        url={video}
        controls={false}
        width="100%"
        height="100%"
        playing={playing}
        onPause={() => {
          setPlaying(false);
        }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          position: "fixed",
          left: 0,
          top: 0,
          padding: "20px",
        }}
      >
        <button
          style={{
            borderRadius: "50%",
            background: "white",
            color: "red",
            border: "2px solid red",
            margin: "10px",
            width: "80px",
            height: "80px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={() => handleStartButtonClick(10, 13)}
        >
          10-13
        </button>
        <button
          style={{
            borderRadius: "50%",
            background: "transparent",
            border: "2px solid red",
            margin: "10px",
            width: "80px",
            height: "80px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
            top: "120px", // Adjust this value for the next button
          }}
          onClick={() => handleStartButtonClick(13, 18)}
        >
          <img src={logo} alt="Stop" width="40" height="40" />
        </button>
        <button
          style={{
            borderRadius: "50%",
            background: "white",
            color: "red",
            border: "2px solid red",
            margin: "10px",
            width: "80px",
            height: "80px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
            top: "240px", // Adjust this value for the next button
          }}
          onClick={() => handleStartButtonClick(18, 26)}
        >
          18-26
        </button>
        
        <button
          style={{
            borderRadius: "50%",
            background: "white",
            color: "red",
            border: "2px solid red",
            margin: "10px",
            width: "80px",
            height: "80px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
            top: "240px", // Adjust this value for the next button
          }}
          onClick={() => handleStartButtonClick(26, 31)}
        >
          26-31
        </button>
        <button
          style={{
            borderRadius: "50%",
            background: "white",
            color: "red",
            border: "2px solid red",
            margin: "10px",
            width: "80px",
            height: "80px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
            top: "240px", // Adjust this value for the next button
          }}
          onClick={() => handleStartButtonClick(31, 34)}
        >
          31-34
        </button>
        <button
          style={{
            borderRadius: "50%",
            background: "white",
            color: "red",
            border: "2px solid red",
            margin: "10px",
            width: "80px",
            height: "80px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
            top: "240px", // Adjust this value for the next button
          }}
          onClick={() => handleStartButtonClick(34, 40)}
        >
          34-40
        </button>
        <button
          style={{
            borderRadius: "50%",
            background: "white",
            color: "red",
            border: "2px solid red",
            margin: "10px",
            width: "80px",
            height: "80px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
            top: "240px", // Adjust this value for the next button
          }}
          onClick={() => handleStartButtonClick(40, 48)}
        >
          40-48
        </button>
      </div>
    </div>
  );
};

export default VideoPlayer;
